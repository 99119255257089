import React from 'react'
import './Hero-section.css'
import Nishan from './Nishan'
import {BsFacebook, BsGithub} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import { NavLink } from  'react-router-dom'
const HeroSection = () => {
  return (<>
    <div className='hero-section'>
    <div className='profile-pic'>
<img src='./images/60.png' alt='logo'/>
    </div>
    <div className='details'>
<h1>Hello</h1>
<p className='name'>I'm Nishan Shrestha</p>
<div className='status'>
<h1><Nishan/></h1>
</div>
<div className='location'>
<p>From Nepal</p>
</div>
<div className='buttons'>
<NavLink to="/contact"><button>Contact me</button></NavLink>

<a href='./images/nishan.pdf' download='./images/nishan.pdf'><button>Download CV</button></a>

</div>
    </div>

<div className='social-icons'>
    <ul>
    <li className='facebook'><a href='https://www.facebook.com/nishan.crestha.142' target='none'><BsFacebook/></a></li>
    <li className='instagram'><a href='https://www.instagram.com/nishanstha98/' target='none'><AiFillInstagram/></a></li>
    <li className='github'><a href='https://github.com/nishanstha12' target='none'><BsGithub/></a></li>
    </ul>
    </div>
    </div>
    </>
  )
}

export default HeroSection