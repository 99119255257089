import React from "react";
import './index.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact"
import About from "./Pages/About"
import Skills from "./Pages/Skills"
import Project from "./Pages/Project";
import ScrollUp from "./Pages/ScrollUp";
import Error from "./Pages/Error";
const  App= ()=>{
   return ( <BrowserRouter>
<Nav/>
<ScrollUp/>
  <Routes>
<Route path="/" element={<Home/>} />
<Route path="/home" element={<Home/>} />
<Route path="/about" element={<About/>} />
<Route path="/project" element={<Project/>} />
<Route path="/contact" element={<Contact/>} />
<Route path="/skills" element={<Skills/>} />
<Route path="*" element={<Error/>} />
  </Routes>
  </BrowserRouter>
  );
}
export default App;
