import React from 'react'
import './skills.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
const Skills = () => {
  return (<>
<div className='common-heading'>
<h1>Skills</h1>
</div>
    <div className='skill-section'>
    <div className='skill-img'>
    <img src='./images/skills.png' alt='skills'/>

    </div>
   <div className='My-skills'>
   <div className='knowledge'>
   <h2>HTML</h2>
   <ProgressBar animated now={95} />
   </div>
   <div className='knowledge'>
   <h2>CSS</h2>
   <ProgressBar animated now={85} />
   </div>
   <div className='knowledge'>
   <h2>Javascript</h2>
   <ProgressBar animated now={60} />
   </div>
   <div className='knowledge'>
   <h2>React JS</h2>
   <ProgressBar animated now={65} />
   </div>
   <div className='knowledge'>
   <h2>Photoshop</h2>
   <ProgressBar animated now={35} />
   </div>

   </div> 
    
    
    
    </div>
    
    
    
    </>
  )
}

export default Skills