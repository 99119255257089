import React from 'react'
import './about.css'
const About = () => {
  return (<>
    <div className='common-heading'> 
    <h1>About </h1>
    </div>
    <div className='About-Me'>
    <div className='about-pic'>
    <img src='./images/60.png' alt='About'/>
    </div>
    <div className='about-details'>
    <h1>Personal Details</h1>
   <p> Name: Netra Kumar Shrestha</p>
   <p>Gender: Male</p>
   <p>Age: 16</p>
   <p>Education: 10<sup>th</sup> passed</p>
   <p>Country: Nepal</p>
   <p>Language: Nepali</p>
   <p>E-mail:shresthanishan676@gmail.com</p>
   <p>Phone +977 98.......</p>
    <p>Intrested in: Web developing and <br/> programming</p>
    </div>
    <div className='certificate'>
    <h1>Certificate</h1>
    <img src='./images/certificate.jpeg' alt='certificate'/>
    </div>
    </div>
    </>
  )
}

export default About