import React from 'react'
import HeroSection from './HeroSection'
import Skills  from './Skills'
import Contact from './Contact'
import Project from './Project'
import About from './About'
const Home = () => {
  return (
    <div>
<HeroSection/>
<Skills/>
<About/>
<Project/>

<Contact/>

    </div>
  )
}

export default Home