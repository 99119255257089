import React from 'react'
import './Hero-section.css'
const Error = () => {
  return (<>
    <div className='error'>
   <img src='./images/error.jpg' alt='ERROR-404'/>
    </div>
    </> )
}

export default Error