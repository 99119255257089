import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { AiOutlineArrowUp} from 'react-icons/ai'
import './scroll.css'
const ScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false);
const scrollTop=()=>{
window.scrollTo({top:0, left:0, behavior:'smooth'})
}
  useEffect(() => {
    window.addEventListener("scroll", ()=>{
      if(window.scrollY>130){
        setIsVisible(true)
      }
      else{
        setIsVisible(false)
      }
    });
  }, []);
  return <>{ isVisible && (<div className='scrollTop' onClick={scrollTop}>  <AiOutlineArrowUp/>
  </div>)}
 
  </>
 }
    
    

export default ScrollUp