import React from 'react'
import './contact.css'
const Contact = () => {
  return (<>
    <div className='common-heading'> 
    <h1>Contact </h1>
    </div>
    <div className='contact-map'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33933.99431976737!2d87.36218240084388!3d26.620556613423084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ef6f8d49613dd3%3A0xb9fca04d9516acb6!2sHaraicha%2C%20Koshi%20Haraicha%2056600!5e0!3m2!1sen!2snp!4v1665727249482!5m2!1sen!2snp"
     width="100%"
     height="320px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <form className='contact-form' action='https://formspree.io/f/mzbwvwbb' method='post'>
<input type='text' name='FullName' placeholder='Full name' autoComplete='off' required></input>
<input type='email' name='E-mail' placeholder='E-mail'autoComplete='off' required/>
<textarea name='messsage'  rows="4" cols="50" placeholder='Message...'></textarea>
<div className='send-btn'>
<button type="submit">Send</button>
</div>
    </form>
    </>
  )
}
export default Contact;