const MyProjectData=[
    {
        id:1,
        image:'./images/love.png',
        category :'Javascript',
        title : 'Love Calculator',
        demo:'https://nishanstha12.github.io/Love-Calculator/',
        sourceCode:'https://github.com/nishanstha12/Love-Calculator'
    },
    {
        id:2,
        image:'./images/shoes.png',
        category:'HTML/CSS',
        title:'Shoe Customization for sell',
        demo:'https://nishanstha12.github.io/shoes.github.io/',
        sourceCode:'https://github.com/nishanstha12/shoes.github.io'
    },
    {
        id:3,
        image:'./images/school.png',
        category :'React',
        title : 'School Website Practice',
        demo :'https://subijimur.netlify.app/',
        sourceCode:'https://github.com/nishanstha12/SchoolWebsite'
    },
    
{
    id:4,
    image:'./images/notes.png',
    category:'Javascript',
    title:'Simple Note Store app using Javascript',
    demo:'https://nishanstha12.github.io/Notes-store/',
    sourceCode:'https://github.com/nishanstha12/Notes-store'
},
{
    id:5,
    image:'./images/login.png',
    category:'HTML/CSS',
    title:'Login form design by HTML and CSS',
    demo:'https://nishanstha12.github.io/LoginForm/',
    sourceCode:'https://github.com/nishanstha12/LoginForm'
},
    {
        id:6,
        image:'./images/microsoft.png',
        category :'HTML/CSS',
        title : 'Microsoft Website Clone',
        demo :'https://nishanstha12.github.io/MicrosoftHomeClone/',
        sourceCode:'https://github.com/nishanstha12/MicrosoftHomeClone'
    },

    {
        id:7,
        image:'./images/grading.png',
        category :'Javascript',
        title : 'Grading calculation in JS',
        demo :'https://nishanstha12.github.io/GradingJS/',
        sourceCode:'https://github.com/nishanstha12/GradingJS'
    },
    {
        id:8,
        image:'./images/api.png',
        category :'Javascript',
        title : 'Fetching data from API',
        demo :'https://nishanstha12.github.io/FetchDataFromAPI/',
        sourceCode:'https://github.com/nishanstha12/FetchDataFromAPI'
    },
    {
        id:9,
        image:'./images/calculator.png',
        category :'Javascript',
        title : 'Simple calculator',
        demo :'https://nishanstha12.github.io/calculator/',
        sourceCode:'https://github.com/nishanstha12/calculator'
    },
    {
        id:10,
        image:'./images/portfolio.png',
        category :'React',
        title : 'Portfolio',
        demo :'http://netrakumarshrestha.com.np/',
        sourceCode:'https://github.com/nishanstha12/portfolio'
    },

]
export default MyProjectData