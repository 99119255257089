import React from 'react'
import Typed from 'typed.js'
import { useEffect, useRef } from 'react'
const Nishan = () => {
    const el =useRef(null);
    useEffect(()=>{
        const typed = new Typed(el.current,{
            strings :[ 'A Student' , 'Web Developer' ,'Designer',],
            startDelay :300,
            typeSpeed :180,
            backDelay :150,
            backSpeed:300,
            smartBackspace :true,
            showCursor : false,
            loop:true

        });
    })
  return (<>
   <h1 ref={el}></h1>
    
    </>
  )
}

export default Nishan;