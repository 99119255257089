import React from 'react';
import './Nav.css'
import { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";
const Nav = () => {
  const [Show, Hide] = useState(false);
  return (
    <> 
    <nav className="main-nav">
        <div className="logo">
          <h2>
            <span>N</span>ishan
            <span>S</span>tha
          </h2>
        </div>
<div className={Show ? "mobile-menu-link" : "menu-link"}>
          <ul>
            <li>
             <NavLink to="/home">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about">about</NavLink>
            </li>
            <li>
               <NavLink to="/skills">Skills</NavLink>
            </li>
            <li>
            <NavLink to="/project">Projects</NavLink>
          </li>
            <li>
              <NavLink to="/contact">contact</NavLink>
            </li>
          </ul>
        </div>

<div className="hamburger-menu">
<a href="#" onClick={() => Hide(!Show)}>
          <GiHamburgerMenu />
        </a>

        </div>
</nav>
    </>
  );
};
export default Nav;