import React from 'react';
import { useState } from 'react';
import './project.css'
import Button from 'react-bootstrap/Button';
import MyProjectData from './MyProjectData';
const Project = () => {
  const [items, setItems] = useState(MyProjectData);
  const filterProject =(Types)=>{
    const filteredItems = MyProjectData.filter((curElem)=>{
    return curElem.category ===Types
    });
    setItems(filteredItems);
    }
  return (<>
    <div className='common-heading'>
    <h1>My Projects</h1>
    </div>
    <div className='category-btns'>
    <Button onClick={()=>setItems(MyProjectData)}>All</Button>
    <Button onClick={() => filterProject('HTML/CSS')}>HTML/CSS</Button>
    <Button onClick={() => filterProject('Javascript')}>Javascript</Button>
    <Button onClick={() => filterProject('React')}>React</Button>
    </div>
    <div className="row">
 {
  items.map((elem) => {
    const {id,image,category,title,demo,sourceCode} =elem;
    return(<>
      <div className="col col-12 col-md-6 col-lg-4">
      <div className="card d-flex">
      <img src={image} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h1 className="card-title">{title}</h1>
        <div className="card-btns">
        <Button><a href={demo} target='_none'>Live Demo</a></Button>
        <Button><a href={sourceCode} target='_none'>source code</a></Button>
        </div>
      </div>
    </div>
    </div>
    </>
    )
 })
 }

    </div>


    </>
  )
  }

export default Project